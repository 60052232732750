import permissionButtonComponent from './permission-button.vue';
import permissionButtonDisComponent from './permission-button-disabled.vue';

export const permissionButton = {
  install(Vue) {
    Vue.component('PermissionButton', permissionButtonComponent);
  },
};

export const permissionDisButton = {
  install(Vue) {
    Vue.component('PermissionDisButton', permissionButtonDisComponent);
  },
};
export const permissionButtonNew = {
  install(Vue) {
    Vue.component('permissionButtonNew', permissionButtonComponent);
  },
};

export const permissionDisButtonNew = {
  install(Vue) {
    Vue.component('permissionDisButtonNew', permissionButtonDisComponent);
  },
};
