
// crs组前缀
const crsPrefix = '/crs_api';
// 直销组前缀
const rezenPrefix = '/api';

const crsApiMapping = {
  // 获取所有证件类型列表
  queryCertificateList: `${crsPrefix}/merchant/lps/profile/queryCertificateList`,
  // 获取证件类型映射列表
  queryCertificateMappingList: `${crsPrefix}/merchant/dc/queryCertificateMappingList`,
  // 获取PMS类型信息
  getPmsType: `${crsPrefix}/merchant/dc/getPmsType`,
  // 保存集团的证件类型映射
  saveCertificateMappings: `${crsPrefix}/merchant/dc/saveCertificateMappings`,
  // POST 渠道定价策略列表
  queryChannelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelPriceStrategy`,
  // POST 更新渠道定价策略
  updateChannelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/updateChannelPriceStrategy`,
  // POST 添加渠道定价策略
  addChannelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/addChannelPriceStrategy`,
  // POST 更新渠道定价策略状态
  updateChannelPriceStrategyStatus: `${crsPrefix}/merchant/crs/channelPriceStrategy/updateChannelPriceStrategyStatus`,
  // POST 获取集团渠道
  getCrsChannelList: `${crsPrefix}/merchant/crs/channel/getCrsChannelList`,
  getCrsChannelListByScene: `${crsPrefix}/merchant/crs/channel/getCrsChannelListByScene`,
  // 查询分销商实体列表
  queryDistributionEntity: `${crsPrefix}/merchant/crs/distributionEntity/queryDistributionEntity`,
  // 添加分销商实体
  addDistributionEntity: `${crsPrefix}/merchant/crs/distributionEntity/addDistributionEntity`,
  // 获取系统渠道列表
  getSystemChannelList: `${crsPrefix}/merchant/crs/channel/getSystemChannelList`,
  // 获取通路列表
  getSwitchNolList: `${crsPrefix}/merchant/crs/channel/getSwitchNolList`,
  // 查询买断房列表
  queryBuyOutRoomList: `${crsPrefix}/merchant/crs/roomtype/queryBuyOutRoomList`,
  // 获取买断房售卖渠道
  getBuyOutRoomChannel: `${crsPrefix}/merchant/crs/roomtype/getBuyOutRoomChannel`,
  // 设置买断房售卖渠道
  setBuyOutRoomChannel: `${crsPrefix}/merchant/crs/roomtype/setBuyOutRoomChannel`,
  // 获取买断方房量列表
  queryBuyOutRoomInventoryList: `${crsPrefix}/merchant/crs/roominventory/queryBuyOutRoomInventoryList`,
  // 获取酒店的所有房型
  getAllRoomTypeByHotelId: `${crsPrefix}/merchant/crs/roomtype/getAllRoomTypeByHotelId`,
  // 获取买断房详情
  getBuyOutRoomInfoByHotelId: `${crsPrefix}/merchant/crs/roomtype/getBuyOutRoomInfoByHotelId`,
  // 设置买断房
  setBuyOutRoom: `${crsPrefix}/merchant/crs/roomtype/setBuyOutRoom`,
  // 设置买断房房量
  setBuyOutRoomInventory: `${crsPrefix}/merchant/crs/roominventory/setBuyOutRoomInventory`,
  // 修改渠道状态
  updateCrsChannelStatus: `${crsPrefix}/merchant/crs/channel/updateCrsChannelStatus`,
  // 保存渠道
  crsSaveChannel: `${crsPrefix}/merchant/crs/channel/crsSaveChannel`,
  // 获取渠道详情
  getCrsChannelDetail: `${crsPrefix}/merchant/crs/channel/getCrsChannelDetail`,
  // 获取机酒渠道信息
  getChannelPlaneHotelRateplan: `${crsPrefix}/merchant/distribution/getChannelPlaneHotelRateplan`,
  // 获取渠道下酒店列表
  getCrsChannelHotelList: `${crsPrefix}/merchant/crs/channelhotel/getCrsChannelHotelList`,
  // 一键上下线
  setChannelHotelStatusWithOneClick: `${crsPrefix}/merchant/crs/channelhotel/setChannelHotelStatusWithOneClick`,
  // 批量修改渠道下的酒店状态
  batchSetChannelHotelStatus: `${crsPrefix}/merchant/crs/channelhotel/batchSetChannelHotelStatus`,
  // 获取PMS参数映射模板
  getHotelChannelConfigTemplate: `${crsPrefix}/merchant/dc/template/getHotelChannelConfigTemplate`,
  // 获取pms参数配置map
  getPmsGroupConfigMap: `${crsPrefix}/merchant/dc/template/getPmsGroupConfigMap`,
  // 设置pms参数映射
  setHotelChannelConfigTemplate: `${crsPrefix}/merchant/dc/template/setHotelChannelConfigTemplate`,
  // 获取通路配置列表
  getSwitchConfigList: `${crsPrefix}/merchant/distribution/getSwitchConfigList`,
  // 获取通路配置详情
  getSwitchConfigDetail: `${crsPrefix}/merchant/distribution/getSwitchConfigDetail`,
  // 设置通路配置详情
  setSwitchConfigDetail: `${crsPrefix}/merchant/distribution/setSwitchConfigDetail`,
  // 获取分销配置列表
  getHotelDiscountList: `${crsPrefix}/merchant/crs/channel/getHotelDiscountList`,
  // 查询分销配置详情
  getHotelDiscountInfoByGroupType: `${crsPrefix}/merchant/crs/channel/getHotelDiscountInfoByGroupType`,
  // 更新分销配置
  saveHotelDiscount: `${crsPrefix}/merchant/crs/channel/saveHotelDiscount`,
  // 获取酒店映射列表
  getHotelListForPms: `${crsPrefix}/merchant/dc/getHotelListForPms`,
  // 建立酒店映射
  addHotelMapping: `${crsPrefix}/merchant/dc/addHotelMapping`,
  // 修改酒店映射
  modifyHotelMapping: `${crsPrefix}/merchant/dc/modifyHotelMapping`,
  // 获取房型映射列表
  getRoomTypeForPms: `${crsPrefix}/merchant/dc/getRoomTypeForPms`,
  // 获取PMS房型
  getPmsRoomType: `${crsPrefix}/merchant/dc/getPmsRoomType`,
  // 添加房型映射
  addRoomMapping: `${crsPrefix}/merchant/dc/addRoomMapping`,
  // 修改房型映射
  modifyRoomMapping: `${crsPrefix}/merchant/dc/modifyRoomMapping`,
  // 获取集团映射
  getGroupAllMapping: `${crsPrefix}/merchant/dc/getGroupAllMapping`,
  // 保存集团映射
  saveGroupAllMapping: `${crsPrefix}/merchant/dc/saveGroupAllMapping`,
  // 获取携程酒店匹配
  getHotelMappings: `${crsPrefix}/merchant/ctrip/mapping/getHotelMappings`,
  // 设置携程酒店匹配
  setHotelMappings: `${crsPrefix}/merchant/ctrip/mapping/setHotelMappings`,
  // 获取携程房型匹配
  getRoomTypeMappings: `${crsPrefix}/merchant/ctrip/mapping/getRoomTypeMappings`,
  // 携程房型名称
  getCtripRoomTypeList: `${crsPrefix}/merchant/ctrip/mapping/getCtripRoomTypeList`,
  // 同步携程房型静态信息
  syncCtripRoomTypeInfo: `${crsPrefix}/merchant/distribution/syncCtripRoomTypeInfo`,
   // 同步酒店匹配
   refreshOTAHotelMapping: `${crsPrefix}/merchant/ctrip/mapping/refreshOTAHotelMapping`,
  // 批量设置携程房型映射
  batchSetRoomTypeMappings: `${crsPrefix}/merchant/ctrip/mapping/batchSetRoomTypeMappings`,
  // 获取共通酒店匹配
  getHotelMappingsCommon: `${crsPrefix}/merchant/distribution/getHotelMappings`,
  // 保存子酒店匹配
  saveSubHotelMappingList: `${crsPrefix}/merchant/distribution/saveSubHotelMappingList`,
  // 设置共通酒店匹配
  setHotelMappingsCommon: `${crsPrefix}/merchant/distribution/setHotelMappings`,
  // 获取共通房型匹配
  getRoomTypeMappingsCommon: `${crsPrefix}/merchant/distribution/getRoomTypeMappings`,
  // 设置共通房型匹配
  setRoomTypeMappings: `${crsPrefix}/merchant/distribution/setRoomTypeMappings`,
  // 获取公司列表
  getCompanyList: `${crsPrefix}/merchant/csp/company/getCompanyList`,
  // 获取公司等级列表
  getCompanyLevelList: `${crsPrefix}/merchant/csp/level/getCompanyLevelList`,
  // 设置公司状态
  setCompanyStatus: `${crsPrefix}/merchant/csp/company/setCompanyStatus`,
  // 保存协议公司
  saveProtocolCompany: `${crsPrefix}/merchant/csp/company/saveProtocolCompany`,
  // 保存协议公司等级
  saveCompanyLevelSet: `${crsPrefix}/merchant/csp/level/saveCompanyLevelSet`,
  // 查询公司等级房型列表
  queryCompanyLevelRoomTypList: `${crsPrefix}/merchant/csp/levelRoomTyp/queryCompanyLevelRoomTypList`,
  // 查询协议价房型列表
  queryRoomTypList: `${crsPrefix}/crs/roomTyp/queryRoomTypList`,
  // 保存协议价房型
  saveCompanyLevelRoomTyp: `${crsPrefix}/merchant/csp/levelRoomTyp/saveCompanyLevelRoomTyp`,
  // 获取房型列表
  getHotelChannelRoomTypeList: `${crsPrefix}/merchant/crs/channel/getHotelChannelRoomTypeList`,
  // 获取房量
  queryRoomInventoryList: `${crsPrefix}/merchant/crs/roominventory/queryRoomInventoryList`,
  // 获取产品列表
  getHotelChannelProductList: `${crsPrefix}/merchant/crs/channel/getHotelChannelProductList`,
  // 设置房量
  setRoomInventory: `${crsPrefix}/merchant/crs/roominventory/setRoomInventory`,
  // 根据渠道id查询产品列表
  queryProductListByChannelId: `${crsPrefix}/merchant/crs/channel/queryProductListByChannelId`,
  // 根据渠道id查询产品列表-多选
  queryProductListByChannelIds: `${crsPrefix}/merchant/crs/channel/queryProductListByChannelIds`,
  // 查询房价列表
  queryRoomPriceList: `${crsPrefix}/merchant/crs/roomprice/queryRoomPriceList`,
  // 查询协议价
  getHotelLevelInfoForBackendByRoomtype: `${crsPrefix}/merchant/csp/companyBackend/getHotelLevelInfoForBackendByRoomtype`,
  // 设置房价
  setRoomPrice: `${crsPrefix}/merchant/crs/roomprice/setRoomPrice`,
  // 查询房型列表
  getRoomTypeList: `${crsPrefix}/merchant/crs/roomtype/getRoomTypeList`,
  // 修改房型状态
  batchUpdateRoomTypeStatus: `${crsPrefix}/merchant/crs/roomtype/batchUpdateRoomTypeStatus`,
  // 保存房型
  saveRoomType: `${crsPrefix}/merchant/crs/roomtype/saveRoomType`,
  // 获取床型列表
  getBedTypeList: `${crsPrefix}/merchant/crs/roomtype/getBedTypeList`,
  // 获取房型详情
  getRoomTypeDetail: `${crsPrefix}/merchant/crs/roomtype/getRoomTypeDetail`,
  // 获取房价列表
  getCrsRateList: `${crsPrefix}/merchant/crs/rate/getCrsRateList`,
  // 修改房价状态
  updateCrsRateStatus: `${crsPrefix}/merchant/crs/rate/updateCrsRateStatus`,
  // 查询会员等级
  getAllMemberRank: `${crsPrefix}/merchant/lps/memberrank/getAllMemberRank`,
  // 查询房价详情
  getCrsRateDetail: `${crsPrefix}/merchant/crs/rate/getCrsRateDetail`,
  // 保存房价
  saveCrsRate: `${crsPrefix}/merchant/crs/rate/saveCrsRate`,
  // 获取渠道映射列表
  getCrsChannelMappingList: `${crsPrefix}/merchant/crs/channelmapping/getCrsChannelMappingList`,
  // 获取产品列表
  getCrsProductList: `${crsPrefix}/merchant/crs/product/getCrsProductList`,
  // 修改产品状态
  updateCrsProductStatus: `${crsPrefix}/merchant/crs/product/updateCrsProductStatus`,
  // 获取价格列表
  getCrsSurplusRateList: `${crsPrefix}/merchant/crs/rate/getCrsSurplusRateList`,
  // 保存产品
  saveCrsProduct: `${crsPrefix}/merchant/crs/product/saveCrsProduct`,
  // 上传图片
  uploadimage: `${crsPrefix}/merchant/crs/image/uploadimage`,
  // POST 修改渠道酒店推送闪住订单费用状态
  setChannelHotelPushCostStatus: `${crsPrefix}/merchant/crs/channelhotel/setChannelHotelPushCostStatus`,
  // POST 获取PMS证件类型列表
  getPmsCertificateList: `${crsPrefix}/merchant/dc/getPmsCertificateList`,
  // 查询取消规则列表
  queryList: `${crsPrefix}/merchant/crs/cancelRule/queryList`,
  // 获取取消规则详情
  getCancelRule: `${crsPrefix}/merchant/crs/cancelRule/getCancelRule`,
  // 保存取消规则
  saveCancelRule: `${crsPrefix}/merchant/crs/cancelRule/saveCancelRule`,
  // 获取预定规则列表
  queryRestrictRule: `${crsPrefix}/merchant/crs/restrictRule/queryRestrictRule`,
  // 获取预定规则详情
  getRestrictRule: `${crsPrefix}/merchant/crs/restrictRule/getRestrictRule`,
  // 保存预定规则
  saveRestrictRule: `${crsPrefix}/merchant/crs/restrictRule/saveRestrictRule`,
  // 获取基础价格列表接口
  getCrsBaseRateList: `${crsPrefix}/merchant/crs/rate/getCrsBaseRateList`,
  // 获取基础价每日房价列表
  queryBaseRoomPriceList: `${crsPrefix}/merchant/crs/roomprice/queryBaseRoomPriceList`,
  // POST 渠道酒店定价策略列表
  queryChannelHotelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelHotelPriceStrategy`,
  // POST 设置渠道酒店定价策略
  setChannelHotelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/setChannelHotelPriceStrategy`,
  // POST 查询分销商
  getDistribution: `${crsPrefix}/merchant/ibe/distribution/getDistribution`,
  // POST 查询分销商黑白名单
  getNameListByDistributionId: `${crsPrefix}/merchant/ibe/distribution/getNameListByDistributionId`,
  // POST 新建分销商
  addDistribution: `${crsPrefix}/merchant/ibe/distribution/addDistribution`,
  // POST 修改分销商
  editDistribution: `${crsPrefix}/merchant/ibe/distribution/editDistribution`,
  // POST 添加分销商黑白名单
  addDistributionNameList: `${crsPrefix}/merchant/ibe/distribution/addDistributionNameList`,
  // 分销渠道开关房
  roomStatusSwitch: `${crsPrefix}/merchant/crs/roominventory/roomStatusSwitch`,
  // POST 查询渠道设置的自动生成房量
  getChannelGenerateInventory: `${crsPrefix}/merchant/crs/channel/getChannelGenerateInventory`,
  // POST 保存渠道设置的自动生成房量
  setChannelGenerateInventory: `${crsPrefix}/merchant/crs/channel/setChannelGenerateInventory`,
  // 数据监控 获取渠道日志详情
  getPushPriceInventoryDetailLog: `${crsPrefix}/log/distribution/thirdpartlog/getPushPriceInventoryDetailLog`,
  // 数据监控  获取日志列表
  getDistributionThirdPartLogList: `${crsPrefix}/log/distribution/thirdpartlog/getDistributionThirdPartLogList`,
  // 数据监控
  getDistributionThirdPartLogOperateType: `${crsPrefix}/log/distribution/thirdpartlog/getDistributionThirdPartLogOperateType`,
  // 数据监控
  queryCrsOperateLog: `${crsPrefix}/log/crsOperateLog/queryCrsOperateLog`,
  // 数据监控
  queryAllCrsOperateApi: `${crsPrefix}/log/crsOperateLog/queryAllCrsOperateApi`,
  // POST 根据渠道获取基础价信息
  getBaseRateByChannel: `${crsPrefix}/merchant/crs/rate/getBaseRateByChannel`,
  // POST 根据来源获取基础价信息
  getBaseRateListBySource: `${crsPrefix}/merchant/crs/rate/getBaseRateListBySource`,
  // 获取火眼日志列表
  queryFireeyeLog: `${crsPrefix}/merchant/fireeye/queryFireeyeLog`,
  // 获取火眼日志模块类型
  queryFireeyeModuleType: `${crsPrefix}/merchant/fireeye/queryFireeyeModuleType`,
  // 获取火眼日志详情
  queryFireeyeLogDetail: `${crsPrefix}/merchant/fireeye/queryFireeyeLogDetail`,
  // POST 酒店结算价列表
  queryChannelHotelDiscount: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelHotelDiscount`,
  // POST 设置渠道酒店折扣
  setChannelHotelDiscount: `${crsPrefix}/merchant/crs/channelPriceStrategy/setChannelHotelDiscount`,
  // POST 查询活动列表
  queryActivity: `${crsPrefix}/crsbizgeneral/activity/queryActivity`,
  // POST 获取活动详情
  getActivityDetail: `${crsPrefix}/crsbizgeneral/activity/getActivityDetail`,
  // POST 创建活动
  createActivity: `${crsPrefix}/crsbizgeneral/activity/createActivity`,
  // POST 删除活动
  deleteActivity: `${crsPrefix}/crsbizgeneral/activity/deleteActivity`,
  // POST 查询活动报名进度
  queryActivitySubscribeProgress: `${crsPrefix}/crsbizgeneral/activity/queryActivitySubscribeProgress`,
  // POST 更新活动
  updateActivity: `${crsPrefix}/crsbizgeneral/activity/updateActivity`,
  // POST 上传活动邀请函
  uploadInvitationLetter: `${crsPrefix}/crsbizgeneral/activity/uploadInvitationLetter`,
  // POST 上传活动缩略图
  uploadThumbnail: `${crsPrefix}/crsbizgeneral/activity/uploadThumbnail`,
  // GET 导出活动报名进度
  exportActivitySubscribeProgress: `${crsPrefix}/crsbizgeneral/activity/exportActivitySubscribeProgress`,
  // GET 导出活动报名结果
  exportActivitySubscribeResult: `${crsPrefix}/crsbizgeneral/activity/exportActivitySubscribeResult`,
  // POST 重发协议公司邮件
  sendProtocolCompanyEmail: `${crsPrefix}/merchant/csp/company/sendProtocolCompanyEmail`,
  // POST 集团获取渠道房价列表数据
  queryGroupRoomPriceList: `${crsPrefix}/merchant/crs/roomprice/queryGroupRoomPriceList`,
  // 获取渠道加价是否设置会员等级
  getCrsReleaseRateConfig: `${crsPrefix}/merchant/crs/rate/getCrsReleaseRateConfig`,
  // 渠道结算价定价策略列表
  queryChannelPriceStrategyTemplateList: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelPriceStrategyTemplateList`,
  // POST 酒店结算价定价策略列表
  queryChannelPriceDiscountTemplateList: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelPriceDiscountTemplateList`,
  // 获取操作员列表
  getStaffListByType: `${crsPrefix}/staff/getStaffListByType`,
  // 批量设置渠道房量
  batchSetRoomInventory: `${crsPrefix}/merchant/crs/roominventory/batchSetRoomInventory`,
  // 获取批量设定渠道房态配置
  getBatchSetRoomInventoryConfig: `${crsPrefix}/merchant/crs/roominventory/getBatchSetRoomInventoryConfig`,
  // 获取最新一条批量设定渠道房态日志
  getLastBatchSetRoomInventoryLog: `${crsPrefix}/merchant/crs/roominventory/getLastBatchSetRoomInventoryLog`,
  // 设置酒店渠道房态
  setHotelRoomInventory: `${crsPrefix}/merchant/crs/roominventory/setHotelRoomInventory`,
  // 终止批量设定渠道房态
  terminateBatchSetRoomInventory: `${crsPrefix}/merchant/crs/roominventory/terminateBatchSetRoomInventory`,
  // 查询集团所有协议公司
  queryAllGroupProtocolCompany: `${crsPrefix}/merchant/csp/company/queryAllGroupProtocolCompany`,
  // 获取渠道可选价格来源列表
  getChannelBaseRateSourceList: `${crsPrefix}/merchant/crs/channel/getChannelBaseRateSourceList`,
  // 查询二级渠道
  queryDistributionBySystemChannel: `${crsPrefix}/merchant/ibe/distribution/queryDistributionBySystemChannel`,
  // 设置二级渠道
  setDistributionBySystemChannel: `${crsPrefix}/merchant/ibe/distribution/setDistributionBySystemChannel`,
  // 获取集团酒店价格来源
  getHotelRateSource: `${crsPrefix}/merchant/crs/channelhotel/getHotelRateSource`,
  // 设置集团酒店价格来源
  setChannelHotelRateSource: `${crsPrefix}/merchant/crs/channelhotel/setChannelHotelRateSource`,
  // 获取丽呈携程会员mapping
  getLcCtripMemberLevelMapping: `${crsPrefix}/merchant/crs/roomprice/getLcCtripMemberLevelMapping`,
  // 更新丽呈携程会员mapping
  updateLcCtripMemberLevelMapping: `${crsPrefix}/merchant/crs/roomprice/updateLcCtripMemberLevelMapping`,
  // 新-设置ctrip的子酒店mapping列表
  setSubHotelMappingList: `${crsPrefix}/merchant/distribution/setSubHotelMappingList`,
  // 直销（小程序，H5，支付宝）渠道结算价列表
  queryDirectChannelHotelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryDirectChannelHotelPriceStrategy`,
  // 设置直销（小程序，H5，支付宝）渠道结算价
  setDirectChannelHotelPriceStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/setDirectChannelHotelPriceStrategy`,
  // 直销卖价查询
  directSellingSearch: `${crsPrefix}/merchant/crs/roomprice/directSellingSearch`,
  // 批量设置取消策略
  batchSetCancelStrategy: `${crsPrefix}/merchant/crs/cancelStrategy/batchSetCancelStrategy`,
  // 获取酒店直连房型状态
  getCtripRoomtypeDcStatus: `${crsPrefix}/merchant/ctrip/mapping/getCtripRoomtypeDcStatus`,
  // 设置酒店直连房型状态
  setCtripRoomtypeDcStatus: `${crsPrefix}/merchant/ctrip/mapping/setCtripRoomtypeDcStatus`,
  // 查询酒店crs总开关列表
  getCrsHotelConfigList: `${crsPrefix}/merchant/crs/hotelConfig/getCrsHotelConfigList`,
  // 修改酒店crs总开关
  setCrsHotelConfigSwitch: `${crsPrefix}/merchant/crs/hotelConfig/setCrsHotelConfigSwitch`,
  // 查询酒店校验日志信息
  selectHotelCheckLogInfo: `${crsPrefix}/merchant/crs/hotelConfig/selectHotelCheckLogInfo`,
  // 查询活动列表
  queryEventList: `${crsPrefix}/merchant/crs/promotion/queryEventList`,
  // 查询活动酒店名单
  queryEventHotelRegistrationList: `${crsPrefix}/merchant/crs/promotion/queryEventHotelRegistrationList`,
  // 查询活动详情
  queryEventDetail: `${crsPrefix}/merchant/crs/promotion/queryEventDetail`,
  // 报名开关
  updateEventRegistrationSwitch: `${crsPrefix}/merchant/crs/promotion/updateEventRegistrationSwitch`,
  // 查询渠道结算价列表
  queryChannelSettlementPriceList: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelSettlementPriceList`,
  // 查询酒店结算价列表
  queryHotelSettlementPriceList: `${crsPrefix}/crs/merchant/pricingstrategy/queryHotelSettlementPriceList`,
  // 修改渠道结算价
  setChannelSettlementPrice: `${crsPrefix}/crs/merchant/pricingstrategy/setChannelSettlementPrice`,
  // 修改酒店结算价
  setHotelSettlementPrice: `${crsPrefix}/crs/merchant/pricingstrategy/setHotelSettlementPrice`,
  // POST 集团获取渠道房价列表数据CRS2
  queryGroupRoomPriceListForCrs2: `${crsPrefix}/merchant/crs/roomprice/queryGroupRoomPriceListForCrs2`,
  // 查询携程优惠券列表
  getCtripCouponList: `${crsPrefix}/merchant/crs/ctripcoupon/getCtripCouponList`,
  // 设置携程优惠券状态
  setCtripCouponStatus: `${crsPrefix}/merchant/crs/ctripcoupon/setCtripCouponStatus`,
  // 查询携程优惠券详情和适用酒店
  getCtripCouponAndRange: `${crsPrefix}/merchant/crs/ctripcoupon/getCtripCouponAndRange`,
  // 修改携程优惠券
  updateCtripCoupon: `${crsPrefix}/merchant/crs/ctripcoupon/updateCtripCoupon`,
  // 新建携程优惠券
  createCtripCoupon: `${crsPrefix}/merchant/crs/ctripcoupon/createCtripCoupon`,
  // 下载携程优惠券列表
  downloadCtripCouponHotelProductList: `${crsPrefix}/merchant/crs/ctripcoupon/downloadCtripCouponHotelProductList`,
  // 查询酒店名单列表
  getCtripCouponHotelProductList: `${crsPrefix}/merchant/crs/ctripcoupon/getCtripCouponHotelProductList`,
  // 有丽呈酒店的城市列表-api
  queryCityList: `${crsPrefix}/rms/config/queryCityList`,
  // 品牌列表
  getBrandList: `${rezenPrefix}/merchant/product/hotel/getBrandList`,
  // 酒店列表
  searchHotels: `${rezenPrefix}/merchant/product/hotel/searchHotels`,
  // crs2.0查询渠道现付佣金
  queryChannelPayCashSettlementCommissionForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelPayCashSettlementCommissionForCrs2`,
  // crs2.0设置渠道现付佣金
  setChannelPayCashSettlementCommissionForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/setChannelPayCashSettlementCommissionForCrs2`,
  // crs2.0查询酒店现付佣金
  queryHotelPayCashSettlementCommissionForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryHotelPayCashSettlementCommissionForCrs2`,
  // crs2.0设置酒店现付佣金
  setHotelPayCashSettlementCommissionForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/setHotelPayCashSettlementCommissionForCrs2`,
  // crs2.0查询酒店现付卖价策略
  queryChannelPayCashPriceStrategyForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelPayCashPriceStrategyForCrs2`,
  // crs2.0设置酒店现付卖价策略
  setChannelPayCashPriceStrategyForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/setChannelPayCashPriceStrategyForCrs2`,
  // crs2.0查询价格level
  queryChannelPriceLevel: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelPriceLevel`,
  // 渠道酒店1.0现付定价策略列表
  queryChannelHotelPricePayCashStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelHotelPricePayCashStrategy`,
  // 设置渠道酒店1.0现付定价策略
  setChannelHotelPricePayCashStrategy: `${crsPrefix}/merchant/crs/channelPriceStrategy/setChannelHotelPricePayCashStrategy`,
  // crs1.0设置酒店现付佣金
  setHotelPayCashSettlementCommission: `${crsPrefix}/merchant/crs/channelPriceStrategy/setHotelPayCashSettlementCommission`,
  // crs1.0查询酒店现付佣金
  queryHotelPayCashSettlementCommission: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryHotelPayCashSettlementCommission`,
  // crs1.0设置渠道现付佣金
  setChannelPayCashSettlementCommission: `${crsPrefix}/merchant/crs/channelPriceStrategy/setChannelPayCashSettlementCommission`,
  // crs1.0查询渠道现付佣金
  queryChannelPayCashSettlementCommission: `${crsPrefix}/merchant/crs/channelPriceStrategy/queryChannelPayCashSettlementCommission`,
  // 查询酒店渠道支付类型和上线状态
  getChannelHotelPayment: `${crsPrefix}/merchant/crs/channelhotel/getChannelHotelPayment`,
  // 设置酒店渠道支付类型
  setChannelHotelPayment: `${crsPrefix}/merchant/crs/channelhotel/setChannelHotelPayment`,
  // 获取所有的渠道现付开关模板
  getChannelHotelPaymentTemplateList: `${crsPrefix}/merchant/crs/channelhotel/getChannelHotelPaymentTemplateList`,
  // 设置现预付
  setChannelDistributionPayment: `${crsPrefix}/merchant/ibe/distribution/setChannelDistributionPayment`,
  // crs2.0查询酒店结算价模板
  queryHotelSettlementPriceTemplate: `${crsPrefix}/crs/merchant/pricingstrategy/queryHotelSettlementPriceTemplate`,
  // crs2.0查询渠道酒店卖价策略模板
  queryChannelPayCashPriceStrategyTempalteTemplateForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelPayCashPriceStrategyTempalteTemplateForCrs2`,
  // crs2.0查询渠道现付佣金模板
  queryChannelPayCashSettlementCommissionTemplateForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelPayCashSettlementCommissionTemplateForCrs2`,
  // crs2.0查询酒店现付佣金模板
  queryHotelPayCashSettlementCommissionTemplateForCrs2: `${crsPrefix}/crs/merchant/pricingstrategy/queryHotelPayCashSettlementCommissionTemplateForCrs2`,
  // crs2.0查询渠道结算价模板
  queryChannelSettlementPriceTemplate: `${crsPrefix}/crs/merchant/pricingstrategy/queryChannelSettlementPriceTemplate`,
  // 查询渠道会员等级名称
  getChannelMemberRank: `${crsPrefix}/merchant/crs/channel/getChannelMemberRank`,
  // crs2.0查询酒店的价格level和折扣
  getHotelPriceLevelList: `${crsPrefix}/crs/merchant/pricingstrategy/getHotelPriceLevelList`,
  // 查询渠道建议佣金率
  getRecommendCommission: `${crsPrefix}/crs/merchant/pricingstrategy/getRecommendCommission`,
  // 查询携程APP价格标签
  queryCtripAppPriceTag: `${crsPrefix}/merchant/crs/ctripAppPriceTag/queryCtripAppPriceTag`,
  // 更新携程APP价格标签状态
  updateCtripAppPriceTagStatus: `${crsPrefix}/merchant/crs/ctripAppPriceTag/updateCtripAppPriceTagStatus`,
  // 携程APP价格标签下拉列表
  listCtripAppPriceTag: `${crsPrefix}/merchant/crs/ctripAppPriceTag/listCtripAppPriceTag`,

  // crs查询策略模板列表
  getStrategyModelList: `${crsPrefix}/merchant/crs/channelPriceStrategy/getStrategyModelList`,
  // crs查询策略模板类型
  getStrategyModelType: `${crsPrefix}/merchant/crs/channelPriceStrategy/getStrategyModelType`,

  // 查询渠道分组房量房态
  crsChannelGroupInventoryQuery: `${crsPrefix}/merchant/crs/channelgroup/crsChannelGroupInventoryQuery`,
  // 设置渠道分组房量房态-api
  setChannelGroupInventory: `${crsPrefix}/merchant/crs/channelgroup/setChannelGroupInventory`,
  // 批量设置渠道分组房量房态
  batchSetChannelGroupInventory: `${crsPrefix}/merchant/crs/channelgroup/batchSetChannelGroupInventory`,
  // 获取渠道分组
  getChannelGroup: `${crsPrefix}/merchant/crs/channelgroup/getChannelGroup`,

  // crs2.0查询渠道取消规则列表
  queryChannelCancelRuleInfoList: `${crsPrefix}/merchant/crs/channelHotelCancelRule/queryChannelCancelRuleInfoList`,
  // crs2.0查询渠道酒店取消规则列表
  queryChannelHotelCancelRuleInfoList: `${crsPrefix}/merchant/crs/channelHotelCancelRule/queryChannelHotelCancelRuleInfoList`,
  // 获取支持配置crs2.0渠道酒店取消规则的渠道
  queryChannelListForCancelRule: `${crsPrefix}/merchant/crs/channelHotelCancelRule/queryChannelListForCancelRule`,
  // crs2.0设置渠道取消规则
  setChannelCancelRuleInfo: `${crsPrefix}/merchant/crs/channelHotelCancelRule/setChannelCancelRuleInfo`,
  // crs2.0设置渠道酒店取消规则
  setChannelHotelCancelRuleInfo: `${crsPrefix}/merchant/crs/channelHotelCancelRule/setChannelHotelCancelRuleInfo`,
  // 酒店匹配-酒店房型列表
  queryLinkHotelMapping: `${crsPrefix}/merchant/distribution/queryLinkHotelMapping`,
  // 酒店匹配-酒店房型
  setLinkHotelMapping: `${crsPrefix}/merchant/distribution/setLinkHotelMapping`,
  // 酒店匹配-获取房型列表
  queryLinkHotelRoomTypeMapping: `${crsPrefix}/merchant/distribution/queryLinkHotelRoomTypeMapping`,
  // 酒店匹配-匹配房型保存
  setLinkHotelRoomTypeMapping: `${crsPrefix}/merchant/distribution/setLinkHotelRoomTypeMapping`,
  // 酒店匹配=》设置Ta佣金
  setLinkHotelCommission: `${crsPrefix}/merchant/distribution/setLinkHotelCommission`,
  // 卖价开关状态更新
  updateHotelPmsSalePriceFlag: `${crsPrefix}/merchant/dc/updateHotelPmsSalePriceFlag`,
  // 渠道集合下拉列表接口
  listChannelSet: `${crsPrefix}/merchant/crs/channel/listChannelSet`,
  //查询渠道集合列表-api
  getChannelSetList: `${crsPrefix}/merchant/crs/channel/getChannelSetList`,
  // IBE系统渠道下拉列表接口-api
  listIbeSystemChannel: `${crsPrefix}/merchant/crs/channel/listIbeSystemChannel`,
  // 保存分销商-api
  saveDistribution: `${crsPrefix}/merchant/ibe/distribution/saveDistribution`,
 // 查询保存渠道集合时的可用渠道列表-api
 listChannelForSaveChannelSet: `${crsPrefix}/merchant/crs/channel/listChannelForSaveChannelSet`,
 // 保存渠道集合-api
 saveChannelSet: `${crsPrefix}/merchant/crs/channel/saveChannelSet`,
 // 通过渠道号获取分销商详情-api
 getDistributionDetailByChannelNo: `${crsPrefix}/merchant/ibe/distribution/getDistributionDetailByChannelNo`,
 // 查询酒店列表-api
 getCrsChanelSetHotelList: `${crsPrefix}/merchant/crs/channelhotel/getCrsChanelSetHotelList`,
 // 批量修改渠道酒店上下线状态（渠道集合维度）-api
 batchSetChannelSetHotelStatus: `${crsPrefix}/merchant/crs/channel/batchSetChannelSetHotelStatus`,
 // 一键修改渠道酒店上下线状态（渠道集合维度）-api
 setChannelSetHotelStatusWithOneClick: `${crsPrefix}/merchant/crs/channel/setChannelSetHotelStatusWithOneClick`,
 // 查询集团渠道酒店现预付配置-api
 getCrsChannelSetHotelPaymentList: `${crsPrefix}/merchant/crs/channelhotel/getCrsChannelSetHotelPaymentList`,
 // 集团渠道酒店现预付配置-api
 setCrsChannelSetHotelPayment: `${crsPrefix}/merchant/crs/channelhotel/setCrsChannelSetHotelPayment`,
  //  设置自动上线配置-api
saveChannelHotelAutoOnlineConfig: `${crsPrefix}/merchant/crs/channelhotel/saveChannelHotelAutoOnlineConfig`,
   //查询自动上线配置-api
queryChannelHotelAutoOnlineConfig: `${crsPrefix}/merchant/crs/channelhotel/queryChannelHotelAutoOnlineConfig`,
 // 合同中分销商下拉列表
 queryThirdDistribution: `${crsPrefix}/merchant/ibe/distribution/queryThirdDistribution`,
// 查询渠道库存阈值
getChannelInventoryThreshold: `${crsPrefix}/merchant/crs/channel/getChannelInventoryThreshold`,
// 设置渠道库存阈值
setChannelInventoryThreshold: `${crsPrefix}/merchant/crs/channel/setChannelInventoryThreshold`,
// crs2.0修改酒店结算价（渠道集合维度）
setChannelSetHotelSettlementPrice: `${crsPrefix}/crs/merchant/pricingstrategy/setChannelSetHotelSettlementPrice`,
// crs2.0设置渠道现付卖价策略（渠道集合维度）
setChannelSetPayCashPriceStrategyForCrs2:`${crsPrefix}/crs/merchant/pricingstrategy/setChannelSetPayCashPriceStrategyForCrs2`,
//crs2.0设置酒店现付佣金（渠道集合维度）
setChannelSetHotelPayCashSettlementCommissionForCrs2:`${crsPrefix}/crs/merchant/pricingstrategy/setChannelSetHotelPayCashSettlementCommissionForCrs2`,
//crs2.0设置渠道现付佣金（渠道集合维度）
setChannelSetPayCashSettlementCommissionForCrs2:`${crsPrefix}/crs/merchant/pricingstrategy/setChannelSetPayCashSettlementCommissionForCrs2`,
//crs2.0修改渠道结算价（渠道集合维度）
setChannelSetSettlementPrice:`${crsPrefix}/crs/merchant/pricingstrategy/setChannelSetSettlementPrice`,

};

const rezenApiMapping = {
  // 获取用户关联酒店信息
  getUserRelationHotelsInfo: `${rezenPrefix}/merchant/product/hotel/getUserRelationHotelsInfo`,
  // 修改酒店直连标志
  updateHotelDCFlag: `${rezenPrefix}/merchant/product/hotel/updateHotelDCFlag`,
  // 获取集团信息
  getTenant: `${rezenPrefix}/tenant/getTenant`,
  // 获取操作员角色
  getRoleOfStaff: `${rezenPrefix}/staff/getRoleOfStaff`,
  // 获取固定协议价
  getCRSRatePlan: `${rezenPrefix}/merchant/rateplan/getCRSRatePlan`,
  // 数据监控
  listUnit: `${rezenPrefix}/unit/listUnit`,
  // 数据监控
  queryStaffByDisplayName: `${rezenPrefix}/staff/queryStaffByDisplayName`,
  // 报价查询工具-获取价格详情失败
  getLcCtripPriceCompareDetail: `${rezenPrefix}/merchant/price/compare/getLcCtripPriceCompareDetail`,
};

// api映射关系
export default {
  ...crsApiMapping,
  ...rezenApiMapping,
};
