<!--
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:54
 * @LastEditors:  
 * @LastEditTime: 2022-07-11 11:25:28
 -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
  },
};
</script>
<style >
@import './assets/less/iconfont.css';
.hotel-settlerate-select .el-select__tags-text {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
