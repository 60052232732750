const productRouter = [
  {
    path: 'roomManager/roomstate',
    name: 'roomstate',
    meta: {
      title: '渠道房态管理',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/room-state/index'),
  },
  {
    path: 'roomManager/roomconfig',
    name: 'roomconfig',
    meta: {
      title: '房型配置',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/room-config/index.vue'),
  },
  {
    path: 'roomManager/roomconfig/info/:roomId?',
    name: 'roomconfiginfo',
    meta: {
      title: '房型配置详情',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/room-config-info/index.vue'),
  },
  {
    path: 'roomManager/roomprice',
    name: 'roomprice',
    meta: {
      title: '渠道房价管理',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/room-price/index.vue'),
  },
  {
    path: 'roomManager/priceconfig',
    name: 'hotel-priceconfig',
    meta: {
      title: '价格配置',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/price-config/index.vue'),
  },
  {
    path: 'roomManager/priceconfig/info/:rateId?',
    name: 'priceconfiginfo',
    meta: {
      title: '价格配置详情',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/price-config-info/index.vue'),
  },
  {
    path: 'roomManager/channleconfig',
    name: 'channleconfig',
    meta: {
      title: '分销渠道维护',
      permissionCode: 'cp-hotel-roomProductManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/room-manage/distrub-channel/index.vue'),
  },
  {
    path: 'distribChannelRoom',
    name: 'distribChannelRoom',
    meta: {
      title: '分销渠道开关房',
      permissionCode: 'cp-hotel-distrubChannelRoom',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/distrib-channel-room/index.vue'),
  },
];

// 协议客房管理
const companyRouter = [
  {
    path: 'protocolCompanyManagement',
    name: 'protocolCompanyManagement',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/company-management/company-management.vue'),
  },
  {
    path: 'protocolCompanyDiscount',
    name: 'protocolCompanyDiscount',
    meta: {
      permissionCode: 'cp-hotel-agreementCustomerManage',
      routerType: 3,
    },
    component: () => import('../views/hotel-management/company-management/new-company-discount.vue'),
  },
];

export default [
  ...productRouter,
  ...companyRouter,
];
